import * as React from 'react'
import {Helmet} from "react-helmet";
import Nav from "../components/nav";
import Layout from "../components/layout";
import Footer from "../components/footer";
import {useEffect} from "react";

export default function Testimonials(){
    let loaded = false;

    useEffect(() => {
        loadMore()
    });

    function loadMore(){
        if (loaded === false){
            window.removeEventListener('scroll', loadMore);

            const script = document.createElement("script");

            script.src = "https://apps.elfsight.com/p/platform.js";
            script.async = true;

            document.body.appendChild(script);
            loaded = true
        }



    }
    return(
        <Layout>
            <Helmet>
                <title>Kinetic Impact | Testimonials</title>

            </Helmet>
            <main>
                <Nav/>
                <div className={"py-12"}>
                    <h1 className="lg:text-5xl md:text-4xl text-2xl font-semibold px-4 leading-10 text-gray-800 mt-6 text-center py-12">What our client says</h1>
                    <div className="elfsight-app-2e65ef09-fcec-436a-a93e-212e44a9d122"></div>

                    {/*<Reviews/>*/}
                </div>
                <div className={"pb-12"}>
                    <h1 className="lg:text-5xl md:text-4xl text-2xl font-semibold px-4 leading-10 text-gray-800 mt-6 text-center py-12">Video Testimonials</h1>
                    <div className="relative lg:w-[64rem] lg:h-[36rem] mx-auto pt-[56.25%] lg:pt-0 mb-14">
                        <iframe className="absolute inset-0 w-full h-full lg:h-[36rem]" src="https://www.youtube.com/embed/4fbUg4snf4U"></iframe>

                    </div>
                    <div className="relative lg:w-[64rem] lg:h-[36rem] mx-auto pt-[56.25%] lg:pt-0 mb-14">
                        <iframe className="absolute inset-0 w-full h-full lg:h-[36rem]" src="https://www.youtube.com/embed/U_pneld-w5k"></iframe>

                    </div>

                    <div className="relative lg:w-[64rem] lg:h-[36rem] mx-auto pt-[56.25%] lg:pt-0 mb-14">
                        <iframe className="absolute inset-0 w-full h-full lg:h-[36rem]" src="https://www.youtube.com/embed/nzeA1SZ_KEM"></iframe>

                    </div>
                    <div className="relative lg:w-[64rem] lg:h-[36rem] mx-auto pt-[56.25%] lg:pt-0 mb-14">
                        <iframe className="absolute inset-0 w-full h-full lg:h-[36rem]" src="https://www.youtube.com/embed/ATrUHf2rSHY"></iframe>

                    </div>
                    <div className="relative lg:w-[64rem] lg:h-[36rem] mx-auto pt-[56.25%] lg:pt-0 mb-14">
                        <iframe className="absolute inset-0 w-full h-full lg:h-[36rem]" src="https://www.youtube.com/embed/tHPCZ0MbM58"></iframe>

                    </div>
                </div>
                <Footer/>
            </main>
        </Layout>
    )
}